import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { AuthService, CustomerService } from "../../services";
import ReactPaginate from 'react-paginate';

const CustomersList = ({enableMenu}) => {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [showInactive, setShowInactive] = useState(false);

  // We start with an empty list of items.
	const [currentItems, setCurrentItems] = useState(null);
	// Here we use item offsets; we could also use page offsets
	// following the API or data you're working with.
	const [itemOffset, setItemOffset] = useState(0);
	const [pageCount, setPageCount] = useState(0);
	const itemsPerPage = 50;

  useEffect(() => {
    if (!AuthService.isAdmin()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
    if (AuthService.isAdmin()) {
      enableMenu();
    }
    
    CustomerService.getAllCustomers().then((data) => {
      setCustomers(data.data.sort((a, b) => a.lastname > b.lastname ? 1: -1));
    })
  }, []);

  useEffect(() => {
		// Fetch items from another resources.
		const endOffset = itemOffset + itemsPerPage;
		setCurrentItems(customers?.filter((customer) => customer?.phone.includes(keyword)).slice(itemOffset, endOffset));
		setPageCount(Math.ceil(customers?.filter(customer => customer?.phone.includes(keyword)).length / itemsPerPage));
	}, [customers, itemOffset, keyword]);

  const goToView = (id) => {
    navigate(`/customers/${id}`)
  }
  const redirectTo = () => {
    navigate(`/reservations/list`)
  }

  const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % customers?.filter((customer) =>customer?.phone.includes(keyword)  ).length;
		console.log(
			`User requested page number ${event.selected}, which is offset ${newOffset}`
		);
		setItemOffset(newOffset);
	};
  
  return (
    <>
      <div className="list row mb-4">
        <div className="col-md-12 text-primary">
           <h5>All Customers <button className="btn btn-link btn-sm" onClick={() => {redirectTo()}}>Back</button></h5> 
        </div>
      </div>
      <div className="list row mb-4">
        <div className="col-md-12 mb-4">
          Search By Phone Number: <input className="me-2" type="text" value={keyword}  onChange={(e) => setKeyword(e.currentTarget.value)} />
        </div>
        {/* <div className="col-md-12 mb-4">
          <input className="me-2" type="checkbox" value={showInactive} checked={showInactive === true} onChange={() => setShowInactive(!showInactive)} />
            Show Transferred / Deactivated Customers
        </div> */}
        <div className="col-md-12">
          <table className="personnel-info-table"> 
            <thead>
              <tr>
                <th>Customer Phone</th>
                <th>Customer Name</th>
                <th>Email</th>
                <th>Date Of Birth</th>
                <th>Reservations</th>
              </tr>
              
            </thead>
            <tbody>
              {
                currentItems && currentItems.map(customer => <tr key={customer.id}>
                  <td>{customer?.phone}</td>
                  <td>{customer?.name}</td>
                  <td>{customer?.email}</td>
                  <td>{customer?.birthday}</td>
                  <td><button type="button" class="btn btn-link" onClick={() => goToView(customer.id)}>View</button></td>
                </tr>)
              }
            </tbody>
          </table>
          <ReactPaginate
            className="customers-pagination"
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="pagination justify-content-center"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
            breakClassName="page-item"
            breakLinkClassName="page-link"
          />
						
        </div>
      </div>
    </>
  )
};

export default CustomersList;